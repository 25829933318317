import { navigateToOriginPage } from "~/utils/loginSignup/loginSignup"

export default defineNuxtRouteMiddleware(async (to, from) => {
  const nuxtApp = await useNuxtApp()
  const isLogged = await useIsLogged()

  if (!isLogged?.value) {
    return true
  }

  return nuxtApp.runWithContext(() => navigateToOriginPage())
})
